body {
    height: 100%;
}

html {
    height: 100%;
}

#root {
    height: 100%;
}

.pageClass {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
}

.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

/* firefox only */
@-moz-document url-prefix() { .pageClass {
    overflow-y: auto;
    overflow-x: hidden;
} }

/* login page buttons for FirebaseUI */
button.firebaseui-idp-button > span.firebaseui-idp-text {
    font-weight: 600;
}
button.firebaseui-idp-button[data-provider-id="microsoft.com"] {
    background-color: #ffffff;
    border: #8c8c8c 1px;
}
button.firebaseui-idp-button[data-provider-id="microsoft.com"] > span.firebaseui-idp-text {
    color: #5e5e54;
    font-weight: 600;
    font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica Neue, Arial, sans-serif;
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 30%;
  }
}